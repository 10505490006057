var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('div',{staticClass:"connent_wrap"},[_c('div',{staticClass:"swiper_wrap"},[_c('el-carousel',{attrs:{"height":"400px","trigger":"click"}},[_vm._l((_vm.banner_list),function(item,index){return [(item.pc_url)?_c('el-carousel-item',{key:index},[_c('img',{attrs:{"src":item.pc_url,"alt":""},on:{"click":function($event){return _vm.handleClickBanner(item)}}})]):_vm._e()]})],2)],1),(_vm.live_data && _vm.live_data.length)?_c('div',{staticClass:"live_wrap container"},[_c('div',{staticClass:"title_wrap live"},[_c('div',{staticClass:"t_name"},[_vm._v(_vm._s(_vm.recenly_name))]),_c('div',{staticClass:"change_btn"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","background":"","current-page":_vm.page,"page-size":3,"total":_vm.total,"hide-on-single-page":true},on:{"current-change":_vm.handleCurrentChange,"prev-click":_vm.prev_click,"next-click":_vm.next_click}})],1)]),_c('div',{staticClass:"swiper_live"},_vm._l((_vm.live_data),function(item,index){return _c('live_item',{key:index,attrs:{"live_item":item}})}),1)]):_vm._e(),_c('div',{staticClass:"ad",on:{"click":_vm.handleClickFixed}},[_c('img',{attrs:{"src":_vm.fixed_poster.pc_url,"alt":""}})]),_c('div',{staticClass:"course_bg"},_vm._l((_vm.recommend),function(type_item,index){return (
          type_item.live_recom_info.length && type_item.show_home_nums != 0
        )?_c('div',{key:index,staticClass:"course_wrap container"},[_c('div',{staticClass:"title_wrap live"},[_c('div',{staticClass:"t_name"},[_vm._v(_vm._s(type_item.recommend_name))]),_c('router-link',{attrs:{"to":type_item.pc_url,"target":"_blank"}},[_c('div',{staticClass:"change_btn course_more_btn"},[_vm._v(" 查看更多 "),_c('i',{staticClass:"el-icon-arrow-right"})])])],1),_c('div',{staticClass:"course_box"},[(index !== 0)?_c('div',{staticClass:"course_item bg_Img"},[_c('p',[_vm._v(_vm._s(type_item.recommend_name))])]):_vm._e(),_vm._l((type_item.live_recom_info),function(item,index){return (index < type_item.show_home_nums)?_c('course_item',{key:index,attrs:{"course_item":item}}):_vm._e()})],2)]):_vm._e()}),0)]),_c('div',{staticClass:"slide_nav"},[_c('div',{staticClass:"tool"},[_c('div',{staticClass:"nav_b",on:{"mouseover":function($event){return _vm.handleSlide(2)},"mouseout":function($event){return _vm.handleSlide(0)}}},[_c('div',{staticClass:"img_b"},[_c('img',{attrs:{"src":_vm.slide_num == 2
                ? require('@/assets/slide/call_on.png')
                : require('@/assets/slide/call.png'),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.slide_num == 2),expression:"slide_num == 2"}],staticClass:"nav_hidden"},[_c('b',[_vm._v("电话咨询")]),_c('p',{staticClass:"b_tit"},[_vm._v("4000109168")]),_c('p',{staticClass:"small_tit"},[_vm._v("9:00 - 21:00")])])]),_c('div',{staticClass:"nav_b",on:{"mouseover":function($event){return _vm.handleSlide(3)},"mouseout":function($event){return _vm.handleSlide(0)}}},[_c('div',{staticClass:"img_b"},[_c('img',{attrs:{"src":_vm.slide_num == 3
                ? require('@/assets/slide/wx_on.png')
                : require('@/assets/slide/wx.png'),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.slide_num == 3),expression:"slide_num == 3"}],staticClass:"nav_hidden nav_hidden2"},[_c('b',[_vm._v("关注公众号")]),_c('img',{attrs:{"src":require("@/assets/login/wxgzh.png"),"alt":""}}),_c('p',{staticClass:"b_tit"},[_vm._v("微信关注社科赛斯")]),_c('p',{staticClass:"small_tit"},[_vm._v("随时随地获取上课信息")])])]),_c('router-link',{attrs:{"to":"/mine/feedback?item=6","target":"_blank"}},[_c('div',{staticClass:"nav_b",on:{"mouseover":function($event){return _vm.handleSlide(4)},"mouseout":function($event){return _vm.handleSlide(0)}}},[_c('div',{staticClass:"img_b"},[_c('img',{attrs:{"src":_vm.slide_num == 4
                  ? require('@/assets/slide/feedback_on.png')
                  : require('@/assets/slide/feedback.png'),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.slide_num == 4),expression:"slide_num == 4"}],staticClass:"nav_hidden"},[_c('b',[_vm._v("意见反馈")])])])])],1),(_vm.gotop)?_c('div',{staticClass:"totop",on:{"click":_vm.toTop}},[_c('div',{staticClass:"nav_b",on:{"mouseover":function($event){return _vm.handleSlide(5)},"mouseout":function($event){return _vm.handleSlide(0)}}},[_c('div',{staticClass:"img_b"},[_c('img',{attrs:{"src":_vm.slide_num == 5
                ? require('@/assets/slide/top_on.png')
                : require('@/assets/slide/top.png'),"alt":""}})])])]):_vm._e()]),_c('a',{ref:"target",attrs:{"href":"javascript:;","target":"_blank"}})])}
var staticRenderFns = []

export { render, staticRenderFns }