<template >
  <div v-if="live_item.recommend_type==1&&live_item&&live_item.section_name" class="live_item" @click="handleClickRecenly(live_item)">
    <div class="title letterspace1">{{ live_item.section_name }}</div>
    <div class="course_name letterspace1">{{ live_item.course_name }}</div>
    <div class="bottom">
      <div class="left">
        <div class="time">
          <img src="@/assets/login/time-line.png" alt="" />
          {{ live_item.start_time }}
        </div>
        <div v-if="live_item.section_status==1" class="btn_ sign letterspace1">
          {{live_item.is_join==1?'已预约':'立即预约'}}
        </div>
        <div v-if="live_item.section_status==2" class="btn_ living letterspace1">
          <!-- <span class="zb"></span> -->
          <img src="@/assets/common/live_icon.gif" alt="" style="margin-right:10px;width:13px;">
          直播中
        </div>
        <div v-if="live_item.section_status==3" class="btn_ lived letterspace1">
          直播结束
        </div>
        <div v-if="live_item.section_status==4" class="btn_ playback letterspace1">
          回放
        </div>
        <div v-if="live_item.section_status==5" class="btn_ noplayback letterspace1">
          无回放
        </div>
      </div>
      <div v-if="live_item&&live_item.teacher&&live_item.teacher.teacher_avatar_oval" class="teac">
        <img :src="live_item.teacher.teacher_avatar_oval" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api.js";
export default {
  name: "live_item",
  props: {
    live_item: Object,
  },
  methods: {
    handleClickRecenly(recenly_data) {
      this.handleClickToCommon("recommend_type", recenly_data);
    },
    async handleClickToCommon(type, data) {
      if (data[type] == 1) {
        let islogin = await this.islogin();
        var course_id = data.course_id;
        var chapter_id = data.chapter_id;
        var section_id = data.section_id || data.id;
        if (islogin) {
          this.is_sign(course_id, chapter_id, section_id);
        } else {
          // this.$router.push({
          //   path: `/course_info/${course_id}`,
          // });
          let { href } = this.$router.resolve(`/course_info/${course_id}`);
          window.open(href, "_blank");
        }
      } else if (data[type] == 2) {
        // var is_tabbar = data.page.h5_is_tabbar;
        // if (is_tabbar) {
        //   uni.switchTab({
        //     url: data.page.h5_url,
        //   });
        // } else {
        //   uni.navigateTo({
        //     url: data.page.h5_url,
        //   });
        // }
      } else if (data[type] == 3) {
      } else if (data[type] == 4) {
      } else if (data[type] == 5) {
      }
    },
    islogin() {
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
    async is_sign(course_id, chapter_id, section_id) {
      let res = await api.is_sign({
        course_id: course_id,
      });
      if (res.code == 0) {
        // this.$router.push({
        //   path: `/course_watch/${course_id}/${chapter_id}/${section_id}`,
        // });
        let { href } = this.$router.resolve(`/course_watch/${course_id}/${chapter_id}/${section_id}`);
        window.open(href, "_blank");
      } else {
        // this.$router.push({
        //   path: `/course_info/${course_id}`,
        // });
        let { href } = this.$router.resolve(`/course_info/${course_id}`);
        window.open(href, "_blank");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.live_wrap {
  display: flex;
  align-items: center;
}
.live_item:hover {
  box-shadow: 0 0 10px #dce6f1;
  // box-shadow: 4px 4px 14px 0 rgb(0 0 0 / 8%);
  transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}
.live_item {
  padding: 25px 24px 30px 30px;
  width: 384px;
  height: 192px;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: left;
  transition: all 0.4s;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 24px;
  .title{
    font-size: 16px;
    font-weight: 600;
    color: #151515;
    margin-bottom: 5px;
    overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
  }
  .course_name {
    font-size: 14px;
    color: #999;
    margin-bottom: 13px;
    overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .time img {
        width: 15px;
        height: 15px;
        vertical-align: top;
      }
      font-size: 16px;
      font-weight: 400;
      color: #f96158;
      .btn_ {
        width: 117px;
        height: 34px;
        // background: linear-gradient(90deg, #f98715 0%, #fea54b 100%);
        // background: linear-gradient(90deg, #f3473d 0%, #fc7169 100%);
        border-radius: 17px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 33px;
        span {
          margin-right: 8px;
          display: inline-block;
          width: 11px;
          // padding-right: 20px;
          height: 24px;
          // background:url(../assets/login/time-line.png) ;
          background-position: 98px 0;
          background-repeat: no-repeat;
          background: url(../assets/login/live_h_spanbg.png);
          -webkit-animation: live 0.8s steps(11) infinite;
          -moz-animation: live 0.8s steps(11) infinite;
          -ms-animation: live 0.8s steps(11) infinite;
          -o-animation: live 0.8s steps(11) infinite;
          animation: live 0.8s steps(11) infinite;
        }
      }
    }
    .teac {
      width: 65px;
      height: 86px;
      img {
        width: 65px;
        height: 86px;
      }
    }
  }
}
@-webkit-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@-moz-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@-ms-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@-o-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

.sign {
  background: linear-gradient(90deg, #f98715 0%, #fea54a 100%);
}

.living {
  background: linear-gradient(90deg, #f3453b 0%, #fc736b 100%);
}

.lived {
  color: #9999 !important;
}

.playback {
  background: linear-gradient(90deg, #00ca79 0%, #00e4a7 100%);
}
</style>
