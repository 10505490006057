<template>
  <div class="index">
    <!-- <nav_top /> -->
    <div class="connent_wrap">
      <div class="swiper_wrap">
        <el-carousel height="400px" trigger="click">
          <template v-for="(item, index) in banner_list">
            <el-carousel-item v-if="item.pc_url" :key="index">
              <img :src="item.pc_url" alt="" @click="handleClickBanner(item)" />
            </el-carousel-item>
          </template>
        </el-carousel>
      </div>
      <!-- 近期直播 -->
      <div v-if="live_data && live_data.length" class="live_wrap container">
        <div class="title_wrap live">
          <div class="t_name">{{ recenly_name }}</div>
          <div class="change_btn">
            <el-pagination
              @current-change="handleCurrentChange"
              @prev-click="prev_click"
              @next-click="next_click"
              layout="prev, pager, next"
              background
              :current-page="page"
              :page-size="3"
              :total="total"
              :hide-on-single-page="true"
            >
            </el-pagination>
          </div>
        </div>
        <div class="swiper_live">
          <live_item
            :live_item="item"
            v-for="(item, index) in live_data"
            :key="index"
          />
        </div>
      </div>
      <!-- 广告 -->
      <div class="ad" @click="handleClickFixed">
        <img :src="fixed_poster.pc_url" alt="" />
      </div>
      <!-- 精品好课 -->
      <div class="course_bg">
        <div
          class="course_wrap container"
          v-for="(type_item, index) in recommend"
          :key="index"
          v-if="
            type_item.live_recom_info.length && type_item.show_home_nums != 0
          "
        >
          <div class="title_wrap live">
            <div class="t_name">{{ type_item.recommend_name }}</div>
            <router-link :to="type_item.pc_url" target="_blank">
              <div class="change_btn course_more_btn">
                查看更多
                <i class="el-icon-arrow-right"></i>
              </div>
            </router-link>
          </div>
          <div class="course_box">
            <div class="course_item bg_Img" v-if="index !== 0">
              <p>{{ type_item.recommend_name }}</p>
            </div>

            <course_item
              v-if="index < type_item.show_home_nums"
              :course_item="item"
              v-for="(item, index) in type_item.live_recom_info"
              :key="index"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 底部footer -->
    <!-- <footer_ /> -->

    <!-- 右侧导航 -->
    <div class="slide_nav">
      <div class="tool">
        <!-- <div class="nav_b" @mouseover="handleSlide(1)" @mouseout="handleSlide(0)" @click="handleKefu">
          <div class="img_b"><img :src="slide_num==1?require('@/assets/slide/kefu_on.png'):require('@/assets/slide/kefu.png')" alt="" /></div>
          <div class="nav_hidden" v-show="slide_num==1"  >
            <b>在线咨询</b>
            <p class="b_tit">周一至周日</p>
            <p class="small_tit">9:00 - 21:00</p>
          </div>
        </div> -->
        <div
          class="nav_b"
          @mouseover="handleSlide(2)"
          @mouseout="handleSlide(0)"
        >
          <div class="img_b">
            <img
              :src="
                slide_num == 2
                  ? require('@/assets/slide/call_on.png')
                  : require('@/assets/slide/call.png')
              "
              alt=""
            />
          </div>
          <div class="nav_hidden" v-show="slide_num == 2">
            <b>电话咨询</b>
            <p class="b_tit">4000109168</p>
            <p class="small_tit">9:00 - 21:00</p>
          </div>
        </div>
        <div
          class="nav_b"
          @mouseover="handleSlide(3)"
          @mouseout="handleSlide(0)"
        >
          <div class="img_b">
            <img
              :src="
                slide_num == 3
                  ? require('@/assets/slide/wx_on.png')
                  : require('@/assets/slide/wx.png')
              "
              alt=""
            />
          </div>
          <div class="nav_hidden nav_hidden2" v-show="slide_num == 3">
            <b>关注公众号</b>
            <img src="@/assets/login/wxgzh.png" alt="" />
            <p class="b_tit">微信关注社科赛斯</p>
            <p class="small_tit">随时随地获取上课信息</p>
          </div>
        </div>
        <router-link to="/mine/feedback?item=6" target="_blank">
          <div
            class="nav_b"
            @mouseover="handleSlide(4)"
            @mouseout="handleSlide(0)"
          >
            <div class="img_b">
              <img
                :src="
                  slide_num == 4
                    ? require('@/assets/slide/feedback_on.png')
                    : require('@/assets/slide/feedback.png')
                "
                alt=""
              />
            </div>
            <div class="nav_hidden" v-show="slide_num == 4">
              <b>意见反馈</b>
            </div>
          </div>
        </router-link>
      </div>
      <div v-if="gotop" class="totop" @click="toTop">
        <div
          class="nav_b"
          @mouseover="handleSlide(5)"
          @mouseout="handleSlide(0)"
        >
          <div class="img_b">
            <img
              :src="
                slide_num == 5
                  ? require('@/assets/slide/top_on.png')
                  : require('@/assets/slide/top.png')
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <a ref="target" href="javascript:;" target="_blank"></a>
  </div>
</template>

<script>
// @ is an alias to /src
import live_item from "@/components/live_item.vue";
import course_item from "@/components/course_item.vue";
// import footer_ from "@/components/footer_.vue";
import api from "@/config/api";
import { mapActions } from "vuex";
import { compare } from "@/config/cookie.js";

export default {
  name: "index",
  components: {
    live_item: live_item,
    course_item,
    // footer_
  },
  data() {
    return {
      banner_list: [],
      recenly_name: "",
      live_data: [],
      live_all: [],
      fixed_poster: {},
      recommend: [],
      page: 1,
      total: 0,

      isshow: false,
      slide_num: 0,
      gotop: false,
    };
  },
  methods: {
    ...mapActions(["setColumn", "setUserAndState"]),
    async getData() {
      try {
        const res = await api.home();
        if (res.code == 0) {
          let { banner, column, fixed_poster, nav_info } = res.result;
          this.banner_list = banner;
          this.fixed_poster = fixed_poster;
          this.setColumn(column);
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const res = await api.mate();
        if (res.code == 0) {
          let { near_live, recommend } = res.result;
          this.recenly_name = near_live.recommend_name;
          let _near_live = near_live.live_recom_info.sort(compare("sort"));
          _near_live = _near_live.filter(function (item) {
            return item.recommend_type == 1;
          });
          this.live_all = _near_live;
          this.total = _near_live.length;

          this.live_data = _near_live.slice(this.page - 1, this.page - 1 + 3);
          // recommend[0].live_recom_info[0].course.course_price_type=2
          // recommend[0].live_recom_info[1].course.course_price_type=2
          this.recommend = recommend;
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 把直播数据分成三  等分
    Datas(data, num) {
      let index = 0;
      let array = [];
      while (index < data.length) {
        array.push(data.slice(index, (index += num)));
      }
      return array;
    },
    handleCurrentChange(e) {
      this.page = e;
      this.live_data = this.live_all.slice(
        (this.page - 1) * 3,
        (this.page - 1) * 3 + 3
      );
    },
    prev_click(e) {
      this.page = e;
      this.live_data = this.live_all.slice(
        (this.page - 1) * 3,
        (this.page - 1) * 3 + 3
      );
    },
    next_click(e) {
      this.page = 2;
      this.live_data = this.live_all.slice(
        (this.page - 1) * 3,
        (this.page - 1) * 3 + 3
      );
    },
    handleClickBanner(item) {
      this.handleClickToCommon("banner_type", item);
    },
    handleClickFixed() {
      this.handleClickToCommon("banner_type", this.fixed_poster);
    },
    async handleClickToCommon(type, data) {
      if (data.is_login) {
        // 是否需要登录
        let islogin = await this.islogin();
        if (!islogin) {
          this.isshow = true;
          return;
        }
      }
      if (data[type] == 1) {
        let islogin = await this.islogin();
        if (islogin) {
          this.is_sign(data.relation_id,data);
        } else {
          let target = this.$refs.target;
          target.setAttribute("href", `/course_info/${data.relation_id}`);
          target.click();
          // this.$router.push({
          //   path: `/course_info/${data.relation_id}`,
          // });
        }
      } else if (data[type] == 2) {
        let target = this.$refs.target;
          target.setAttribute("href", data.page.pc_url);
          target.click();
      } else if (data[type] == 3) {
        // this.banner_xcx_url = data.applet.applet_code;
        // this.banner_xcx_show = true;
        // this.$alert(
        //   `<img style="width:180px" src='${data.applet.applet_code}' />`,
        //   `${data.applet.applet_name}`,
        //   {
        //     center: true,
        //     dangerouslyUseHTMLString: true,
        //     showConfirmButton: false,
        //     closeOnClickModal: true,
        //   }
        // )
        //   .then(() => {
        //   })
        //   .catch((action) => {
        //   });
      } else if (data[type] == 4) {
        // this.$router.push({
        //   path: "/agreement/" + data.activity.h5_url,
        // });
        location.href = data.activity.h5_url;
      } else if (data[type] == 5) {
      }
    },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
    async is_sign(course_id,data) {
      if(data.course.course_price_type==2){
				let target = this.$refs.target;
        target.setAttribute("href", `/course_info/${course_id}`);
        target.click();
					return
			}
      let res = await api.is_sign({
        course_id: course_id,
      });
      if (res.code == 0) {
        let target = this.$refs.target;
        target.setAttribute("href", `/course_watch/${course_id}`);
        target.click();
      } else {
        let target = this.$refs.target;
        target.setAttribute("href", `/course_info/${course_id}`);
        target.click();
      }
    },
    handleSlide(num) {
      this.slide_num = num;
    },
    handleKefu() {
      doyoo.util.openChat("g=10085061");
      return false;
    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  created() {
    // this.add();
    this.getData();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style>
.slide_nav {
  position: fixed;
  right: 25px;
  bottom: 100px;
  text-align: left;
  z-index: 2;
}
.tool {
  background: #ffffff;
  border: 1px solid #ededee;
  border-radius: 8px;
}
.nav_b {
  position: relative;
  width: 46px;
  height: 46px;
  cursor: pointer;
  box-sizing: border-box;
}
.nav_b:hover {
  background: #479dff;
  border: 1px solid #479dff;
}
.nav_b:first-child {
  border-radius: 8px 8px 0px 0px;
}
.nav_b:last-child {
  border-radius: 0px 0px 8px 8px;
}

.img_b {
  height: 100%;
  text-align: center;
}
.img_b > img {
  margin-top: 12px;
}
.nav_hidden {
  width: 110px;
  /* height: 89px; */
  background: #ffffff;
  border: 1px solid #ededee;
  border-radius: 8px;
  position: absolute;
  left: -120px;
  top: 0;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* display: none; */
}
.nav_hidden2 {
  width: 152px;
  left: -162px;
}
.nav_hidden b {
  font-size: 16px;
  color: #479dff;
}
.nav_hidden .b_tit {
  font-size: 14px;
  color: #666666;
}
.nav_hidden .small_tit {
  font-size: 12px;
  color: #999999;
}
.nav_hidden img {
  width: 111px;
}
.totop {
  background: #fff;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ededee;
}
.totop .nav_b {
  border-radius: 8px;
}
.swiper_wrap img {
  width: 100%;
}
.swiper_wrap .el-carousel__button {
  height: 4px;
  /* width: 20px; */
}

.change_btn .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #409eff !important;
  color: #fff !important;
}
.index .el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #fff !important;
  color: #606266;
  min-width: 30px;
  border-radius: 50% !important;
}
.index .change_btn .el-pagination.is-background .btn-next {
  background-color: #fff !important;
  border-radius: 50% !important;
}

.index .change_btn .el-pagination.is-background .btn-prev {
  background-color: #fff !important;
  border-radius: 50% !important;
}
</style>
<style lang="less" scoped>
.connent_wrap {
  // background-color: #f5f6f8;
  background-color: #f9fbfd;
}

.connent_wrap {
  padding-top: 16px;
  padding-bottom: 100px;
}
.swiper_wrap {
  height: 400px;
  width: 1200px;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 40px;
  .img {
    height: 400px;
    border-radius: 14px;
    overflow: hidden;
  }
}
.title_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  .t_name {
    font-size: 28px;
    font-weight: bold;
    color: #1f1f1f;
    line-height: 34px;
    width: 145px;
    text-align: left;
  }
  .change_btn {
    display: flex;
    align-items: center;
    .num_wrap {
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      margin-left: 10px;
    }
    .btn.on {
      color: #ffffff;
      background-color: #479dff;
    }
  }
}
.swiper_live {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // overflow: hidden;
}
.course_box {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  .img {
    width: 282px;
    height: 210px;
  }
  .bg_Img {
    p {
      font-size: 62px;
      font-weight: bold;
      color: #ffffff;
      line-height: 210px;
      text-shadow: 0px 0px 4px rgba(253, 149, 10, 0.42);
    }
  }

  .none {
    display: none;
  }
}
.course_item {
  margin-right: 24px;
  margin-bottom: 30px;
}
.course_item:nth-of-type(4n) {
  margin-right: 0;
}
.course_wrap:nth-child(3n + 1) {
  .bg_Img {
    background: url(../assets/login/bg_blue.png) no-repeat;
    background-size: contain;
    width: 282px;
    height: 210px;
    overflow: hidden;
    border-radius: 8px;
  }
}
.course_wrap:nth-child(3n + 2) {
  .bg_Img {
    background: url(../assets/login/bg_green.png) no-repeat;
    background-size: contain;
    width: 282px;
    height: 210px;
    overflow: hidden;
    border-radius: 8px;
  }
}
.course_wrap:nth-child(3n + 3) {
  .bg_Img {
    background: url(../assets/login/bg_yellow.png) no-repeat;
    background-size: contain;
    width: 282px;
    height: 210px;
    overflow: hidden;
    border-radius: 8px;
  }
}
.course_wrap {
  margin-bottom: 30px;
}

.m_50 {
  margin-top: 40px;
}
.ad {
  margin: 0 auto;
  margin: 40px auto 40px;
  width: 1200px;
  height: 75px;
  cursor: pointer;

  img {
    width: 1200px;
    border-radius: 8px;
    height: 75px;
  }
}
.more_btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #7d8494;

  img {
    margin-left: 10px;
  }
}
.course_box .bg {
  width: 282px;
  height: 183px;
}
.course_more_btn {
  font-size: 18px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
</style>
